import React from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import notFound from '../../assets/images/404-image.svg';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Box h="100vh">
      <Flex
        justifyContent="center"
        alignItems="center"
        height="100%"
        flexDirection={['column', null, 'row']}
      >
        <Box>
          <Image
            objectFit="cover"
            alt="NotFound Image"
            src={notFound}
            boxSize={['200px', '300px', '100%']}
          />
        </Box>
        <Box width="40%">
          <Heading color="darkerBlue">Oops!</Heading>
          <Heading color="darkerBlue">Nothing to see here...!</Heading>
          <Text mt="2">
            We can’t seem to find the page you’re looking for! Try going back to
            the previous page, or Contact Us for more information.
          </Text>
          <HStack gap="2" mt="4">
            <Button colorScheme="blue" onClick={() => navigate('/')}>
              Go Back
            </Button>
            <Button colorScheme="blue" variant="ghost">
              Contact Us
            </Button>
          </HStack>
        </Box>
      </Flex>
    </Box>
  );
};

export default PageNotFound;
