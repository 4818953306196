import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
const DashboardWrapper = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLgScreen] = useMediaQuery('(max-width: 992px)');

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const sidebarDisplay = isSidebarOpen ? 'block' : 'none';

  const iconColor = useColorModeValue('gray.600', 'gray.200');

  // close sidebar on small screens by default
  useEffect(() => {
    if (isLgScreen) {
      setIsSidebarOpen(false);
    } else if (!isLgScreen) {
      setIsSidebarOpen(true);
    }
  }, [isLgScreen]);

  return (
    <Box maxH={{ base: 'fit-content', lg: '100vh' }}>
      <Flex>
        {/* Sidebar */}
        <Box
          bg="white"
          borderRight="1px solid"
          borderColor="gray.200"
          width="256px"
          height="100vh"
          display={sidebarDisplay}
          position={{ base: 'fixed', lg: 'unset' }}
          zIndex="99"
          transition="width 0.3s ease-in-out"
          py="8"
        >
          <Sidebar />
        </Box>

        {/* Main Content */}
        <Box px="8" py="8" width="100%" overflowX="hidden" height="100vh">
          <Box width={{ base: '90%', xl: '75%', '2xl': '70%' }}>
            <Outlet />
          </Box>
        </Box>
      </Flex>

      {/* Toggle Sidebar Button */}
      <IconButton
        icon={isSidebarOpen ? <FaChevronLeft /> : <FaChevronRight />}
        aria-label={isSidebarOpen ? 'Collapse Sidebar' : 'Open Sidebar'}
        onClick={toggleSidebar}
        position="fixed"
        top="50%"
        transform="translateY(-50%)"
        zIndex="999"
        display={{ base: 'block', lg: 'none' }}
        color={iconColor}
      />
    </Box>
  );
};

export default DashboardWrapper;
