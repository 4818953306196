import { extendTheme } from '@chakra-ui/react';

const colors = {
  primaryBtnHover: '#6D80F8',
  removeBtnDisabled: '#E6E6E6',
  gray200: '#F4F4F4',
  blue: {
    200: '#9EAAFA',
    500: '#4960F6',
    700: '#071A92',
  },
  teal: {
    200: '#FED89A',
    500: '#FC9C02',
    50: '#FFF5E6',
  },
  gray: {
    50: '#F2F2F2',
    500: '#CCCCCC',
    700: '#4D4D4D',
  },
  green: {
    300: '#68D391',
  },
  darkerBlue: '#040035',
};

const fonts = {
  heading: `'Red Hat Display Variable', sans-serif;`,
  body: `'Onest', sans-serif;`,
};

const components = {
  Button: {
    baseStyle: {
      _disabled: {
        _hover: {
          cursor: 'not-allowed',
        },
      },
    },
  },
};

const theme = extendTheme({
  fonts: fonts,
  colors: colors,
  components: components,
});

export default theme;

// These are the default breakpoints
// const breakpoints = {
//   sm: '30em', // 480px
//   md: '48em', // 768px
//   lg: '62em', // 992px
//   xl: '80em', // 1280px
//   '2xl': '96em', // 1536px
// };

// Internally, we transform to this
// const breakpoints = ['0em', '30em', '48em', '62em', '80em', '96em']; aaray syntax
// or object syntax {{ base: '24px', md: '40px', lg: '56px' }}
// base: From 0em upwards
// md: From 48em upwards
// lg: From 62em upwards

// spacing values for margins, paddings etc
// const spacing = {
//   space: {
//     px: '1px',
//     0.5: '0.125rem',
//     1: '0.25rem',
//     1.5: '0.375rem',
//     2: '0.5rem',
//     2.5: '0.625rem',
//     3: '0.75rem',
//     3.5: '0.875rem',
//     4: '1rem',
//     5: '1.25rem',
//     6: '1.5rem',
//     7: '1.75rem',
//     `8: '2re`m',
//     9: '2.25rem',
//     10: '2.5rem',
//     12: '3rem',
//     14: '3.5rem',
//     16: '4rem',
//     20: '5rem',
//     24: '6rem',
//     28: '7rem',
//     32: '8rem',
//     36: '9rem',
//     40: '10rem',
//     44: '11rem',
//     48: '12rem',
//     52: '13rem',
//     56: '14rem',
//     60: '15rem',
//     64: '16rem',
//     72: '18rem',
//     80: '20rem',
//     96: '24rem',
//   },
// };
