import React from 'react';
import {
  Box,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
  Link,
  Button,
  useToast,
} from '@chakra-ui/react';
import './layout.scss';
import Logo from '../Logo/logo';
import { FaCaretRight } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { supabase } from '../../../utils/supabase/supabaseClient';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../utils/store/reducers/authSlice';

const Sidebar = () => {
  const toast = useToast();
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const {
    user: { firstName, lastName, company },
    assessments,
  } = useSelector(state => state.auth);

  const handleLogOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      toast({
        title: error?.message,
        status: 'error',
      });
    } else {
      dispatch(logout());
    }
  };

  return (
    <Box minW="256px" minH="100%" position="relative">
      <Logo />
      <VStack alignItems="start" px="24px" mt="16">
        <Text fontSize="lg" fontWeight="700">
          {firstName + ' ' + lastName}
        </Text>
        <Text fontSize="sm" fontWeight="500">
          {company}
        </Text>
      </VStack>

      {/* Menu Items */}
      <Box mt="20" p="3">
        <Text fontSize="xl" fontWeight="500" fontFamily="heading" px="10px">
          Assessments
        </Text>
        <List spacing="6" mt="24px">
          {assessments?.length > 0 &&
            assessments.map((assessment, index) => {
              const isActive = path?.includes(assessment?.slug);
              return (
                <Link
                  key={index}
                  textDecoration="none"
                  _hover={{ textDecoration: 'none' }}
                  // href={route.path}
                  href={`/dashboard/${assessment?.slug}`}
                >
                  <ListItem
                    display="flex"
                    alignItems="center"
                    backgroundColor={isActive ? 'gray.50' : 'transparent'}
                    p="1.5"
                    borderRadius="6px"
                    fontWeight={isActive && 'medium'}
                    lineHeight="8"
                  >
                    <ListIcon
                      as={FaCaretRight}
                      color={isActive ? 'teal.200' : 'gray.600'}
                      fontSize="12px"
                    />
                    {assessment?.name}
                  </ListItem>
                </Link>
              );
            })}
        </List>
      </Box>

      {/* logout button */}
      <Box className="logout-link" px="2" position="absolute" bottom="20px">
        <Button
          leftIcon={<FiLogOut />}
          variant="ghost"
          fontWeight="normal"
          color="gray.600"
          onClick={handleLogOut}
        >
          Log Out
        </Button>
      </Box>
    </Box>
  );
};

export default Sidebar;

// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
