import { lazy } from 'react';
import { ASSESSMENT_PATH } from './pageUrls';

//React lazy loading for good application performance
const Assessments = lazy(() => import('../pages/DashboardPages/Assessments'));

const EMPLOYER_ROUTES = [
  {
    routeName: 'Assessments',
    path: ASSESSMENT_PATH,
    component: Assessments,
  },
];

export default EMPLOYER_ROUTES;
