import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      /* Onest Normal*/
      @font-face {
        font-family: 'Onest';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('../assets/fonts/Onest/TTF/Onest-Regular.ttf') format('truetype'), url('../assets/fonts/Onest/WOFF/Onest-Regular.woff') format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'Onest';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('../assets/fonts/Onest/TTF/Onest-Bold.ttf') format('truetype'), url('../assets/fonts/Onest/WOFF/Onest-Bold.woff') format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      `}
  />
)

export default Fonts;