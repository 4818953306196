import { lazy } from 'react';
import {
  EMPLOYER_LOGIN,
  EMPLOYER_SIGNUP,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
} from './pageUrls';

//React lazy loading for good application performance
const EmployerLogin = lazy(() => import('../pages/Auth/Login'));
const EmployerSignup = lazy(() => import('../pages/Auth/Signup'));
const ResetPassword = lazy(() => import('../pages/Auth/ResetPassword'));
const UpdatePassword = lazy(() => import('../pages/Auth/UpdatePassword'));

const PUBLIC_ROUTES = [
  {
    routeName: 'Employer Login',
    path: EMPLOYER_LOGIN,
    component: EmployerLogin,
  },
  {
    routeName: 'Employer Signup',
    path: EMPLOYER_SIGNUP,
    component: EmployerSignup,
  },
  {
    routeName: 'Reset Password',
    path: RESET_PASSWORD,
    component: ResetPassword,
  },
  {
    routeName: 'Update Password',
    path: UPDATE_PASSWORD,
    component: UpdatePassword,
  },
];

export default PUBLIC_ROUTES;
