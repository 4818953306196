import '@fontsource-variable/red-hat-display';
import './assets/fonts/fonts.css';
import React, { Suspense } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import './style/screen.scss';
import Fonts from './style/fonts';
import theme from './style/theme';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PublicRoutes from './routes/publicRoutes';
import EMPLOYER_ROUTES from './routes/EmployeRoutes';
import Loader from './components/common/Loader/loader';
import DashboardWrapper from './components/common/Layout/DashboardWrapper';
import { DASHBOARD_PATH, EMPLOYER_LOGIN } from './routes/pageUrls';
import PageNotFound from './pages/404/404';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import useUserData from './CustomHooks/useUserData';
import { useSelector } from 'react-redux';

function App() {
  const { isAuthorized } = useUserData();
  const { dashboardLandingRoute } = useSelector(state => state.auth);

  return (
    <ChakraProvider
      theme={theme}
      toastOptions={{
        defaultOptions: {
          position: 'top-right',
          isClosable: true,
          duration: 3000,
        },
      }}
    >
      <Fonts />
      <BrowserRouter>
        {/* add suspense and wraps all routes in it */}
        <Suspense fallback={<Loader loading={true} />}>
          <Routes>
            {/* Redirect to dashboard directly when visiting the page */}
            <Route
              element={
                <Navigate
                  to={
                    isAuthorized
                      ? `${DASHBOARD_PATH}/${dashboardLandingRoute}`
                      : EMPLOYER_LOGIN
                  }
                />
              }
              path="/"
            />
            {/* Public Routes */}
            <Route>
              {PublicRoutes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={<route.component />}
                  />
                );
              })}
            </Route>

            {/* Employee dashboard Routes */}
            {/* <Route element={<PrivateRouteProvider isLoggedIn={isLoggedIn} user={user} />}> */}
            <Route element={<ProtectedRoute isAuthorized={isAuthorized} />}>
              {EMPLOYER_ROUTES.map((route, index) => {
                return (
                  <Route key={index} element={<DashboardWrapper />}>
                    <Route path={route.path} element={<route.component />} />;
                  </Route>
                );
              })}
            </Route>
            {/* </404 pageRoute> */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
